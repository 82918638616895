import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Output() startEndContact = new EventEmitter();

  iconMarker = faMapMarkerAlt;
  iconEmail = faEnvelope;
  iconPhone = faPhoneAlt;

  @ViewChild('contact', { static: false }) contact: ElementRef =
    {} as ElementRef;
  @ViewChild('contactTitle', { static: false })
  contactTitle: ElementRef = {} as ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let end =
      this.contactTitle.nativeElement.offsetTop +
      this.contact.nativeElement.getBoundingClientRect().height;
    let start = this.contactTitle.nativeElement.offsetTop - 140;

    if (start && end) this.startEndContact.emit({ start, end });
  }
}
