<header-info [scrollTop]="scrollHeader"></header-info>
<header-top
  [scrollTop]="scrollHeader"
  [scroll]="scrollTop"
  [mobile]="isMobile"
  (changeMenu)="changeMenu($event)"
  [menuClickName]="menuClickName"
  [scrollMenuCheck]="scrollMenuCheck"
></header-top>
<banner (goMenu)="changeMenuClick($event)"></banner>
<about (startEndAbout)="startEndAbout($event)"></about>
<data-numbers
  [scrollActive]="activeNumbers"
  (startEndNumbers)="startEndNumbers($event)"
></data-numbers>
<sample (startEndSample)="startEndSample($event)" [mobile]="isMobile"></sample>
<essay (startEndEssay)="startEndEssay($event)" [mobile]="isMobile"></essay>
<consultancy (startEndConsultancy)="startEndConsultancy($event)"></consultancy>
<client (startEndClient)="startEndClient($event)"></client>
<certification
  (startEndCertification)="startEndCertification($event)"
></certification>
<contact (startEndContact)="startEndContact($event)"></contact>
<footer-office (changeMenu)="changeMenuClick($event)"></footer-office>
<footer-copyright></footer-copyright>

<div class="iconUp">
  <fa-icon [icon]="iconUp" (click)="scroolTop()"></fa-icon>
</div>
