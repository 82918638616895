import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgAnimatedCounterParams } from '@bugsplat/ng-animated-counter';

@Component({
  selector: 'data-numbers',
  templateUrl: './data-numbers.component.html',
  styleUrls: ['./data-numbers.component.scss'],
})
export class DataNumbersComponent implements OnInit {
  @Output() startEndNumbers = new EventEmitter();
  @Input() scrollActive = false;

  updateComponent = true;

  amostras: NgAnimatedCounterParams = {
    start: 10000,
    end: 18000,
    interval: 10,
    increment: 50,
  };
  campAmostragem: NgAnimatedCounterParams = {
    start: 300,
    end: 500,
    interval: 10,
    increment: 1,
  };

  @ViewChild('numbers', { static: false }) numbers: ElementRef =
    {} as ElementRef;
  @ViewChild('numbersTitle', { static: false }) numbersTitle: ElementRef =
    {} as ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.scrollActive) {
      this.updateComponent = false;

      setTimeout(() => {
        this.updateComponent = true;
      }, 10);
    }
  }

  ngAfterViewInit() {
    let end =
      this.numbers.nativeElement.offsetTop +
      this.numbers.nativeElement.getBoundingClientRect().height +
      650;
    let start = this.numbers.nativeElement.offsetTop + 110;

    if (start && end) this.startEndNumbers.emit({ start, end });
  }
}
