<section class="sample" #sample>
  <div class="container">
    <h1 class="title-default" #sampleTitle>Amostragem</h1>

    <div class="row">
      <div class="col-lg-6 col-xs-12 card-am">
        <div class="card-img" *ngIf="objContent.img.length == 1">
          <img
            src="./assets/img/amostragem/{{ objContent.path }}/{{
              objContent.img[0]
            }}"
            alt="{{ objContent.label }}"
            title="{{ objContent.label }}"
          />
        </div>

        <div
          class="testimonials-slider swiper-img swiper"
          *ngIf="changeImgs && objContent.img.length > 1"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let itemImg of objContent.img">
              <div class="testimonial-item">
                <img
                  src="assets/img/amostragem/{{ objContent.path }}/{{
                    itemImg
                  }}"
                  class="img"
                  alt="{{ objContent.label }}"
                  title="{{ objContent.label }}"
                />
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-img"></div>
        </div>

        <p class="txt-img">*Serviços Acreditados ABNT ISO/NBR 17025:2017</p>
      </div>

      <div class="col-lg-6 col-xs-12 card-am">
        <div class="tabs">
          <ul>
            <li
              *ngFor="let item of tabs"
              [ngClass]="{ check: item.check }"
              (click)="tabsChange(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>

        <div *ngFor="let item of tabs">
          <div *ngIf="item.check" class="row">
            <div
              class="col-lg-4 card-item"
              *ngFor="let itemTxt of item.list"
              [ngClass]="{ check: itemTxt.check }"
              (click)="checkItem(itemTxt, item.list)"
            >
              <fa-icon
                [icon]="itemTxt.check ? iconDotCircle : iconCircle"
              ></fa-icon>
              <span>{{ itemTxt.label }}</span>
            </div>
          </div>
        </div>

        <ul class="txt" *ngIf="objContent.txt">
          <li *ngFor="let itemTxt of objContent.txt">
            <fa-icon [icon]="iconCheck" class="icon-left"></fa-icon>
            {{ itemTxt }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
