<div id="hero" class="banner">
  <div
    id="heroCarousel"
    data-bs-interval="4000"
    class="carousel slide carousel-fade"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner" role="listbox">
      <div
        class="carousel-item"
        [ngClass]="{ active: indexBanner == i }"
        *ngFor="let item of listCarrousel; let i = index"
        style="background-image: url( {{ item.url }})"
      >
        <div class="carousel-container">
          <div class="carousel-content">
            <h2 class="animate__animated animate__fadeInDown">
              {{ item.title }}
            </h2>
            <p class="animate__animated animate__fadeInUp">
              {{ item.subTitle }}
            </p>
            <a
              *ngIf="item.btn"
              href="#about"
              class="btn-get-started scrollto animate__animated animate__fadeInUp"
              (click)="goMenulink(item.link)"
              >{{ item.btn }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <a class="carousel-control-prev" (click)="prev()" role="button">
      <fa-icon [icon]="iconLeft"></fa-icon>
    </a>

    <a class="carousel-control-next" (click)="next()" role="button">
      <fa-icon [icon]="iconRight"></fa-icon>
    </a>

    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        *ngFor="let item of listCarrousel; let i = index"
        [ngClass]="{ active: indexBanner == i }"
        (click)="changeBannerBullet(i)"
      ></li>
    </ol>
  </div>
</div>
