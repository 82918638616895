<div class="header-info" [ngClass]="{ 'header-scrolled': scrollTop }">
  <div class="container">
    <div class="content-max">
      <ul class="info">
        <li>
          <fa-icon [icon]="iconEnvelope"></fa-icon>
          <a href="mailto:contato@aqtca.com.br">contato@aqtca.com.br</a>
        </li>
        <li>
          <fa-icon [icon]="iconPhone"></fa-icon>
          <span>(13) 3223-8410</span>
        </li>
      </ul>

      <ul class="redes">
        <li>
          <a
            href="https://instagram.com/aqtcaconsultoria?utm_medium=copy_link"
            target="_blank"
            ><fa-icon [icon]="iconInstagran"></fa-icon
          ></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/aqtca/" target="_blank"
            ><fa-icon [icon]="iconLinkedin"></fa-icon
          ></a>
        </li>
      </ul>
    </div>
  </div>
</div>
