import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderInfoComponent } from './components/header-info/header-info.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './components/header/header.component';
import { BannerComponent } from './components/banner/banner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from './components/about/about.component';
import { DataNumbersComponent } from './components/data-numbers/data-numbers.component';
import { NgAnimatedCounterModule } from '@bugsplat/ng-animated-counter';
import { SampleComponent } from './components/sample/sample.component';
import { EssayComponent } from './components/essay/essay.component';
import { PostsComponent } from './components/posts/posts.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterOfficeComponent } from './components/footer-office/footer-office.component';
import { FooterCopyrightComponent } from './components/footer-copyright/footer-copyright.component';
import { ConsultancyComponent } from './components/consultancy/consultancy.component';
import { BreakpointObserverService } from './service/mobile-resize.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TabsFormsComponent } from './components/contact/tabs-forms/tabs-forms.component';
import { CertificationComponent } from './components/certification/certification.component';
import { ClientComponent } from './components/client/client.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderInfoComponent,
    HeaderComponent,
    BannerComponent,
    AboutComponent,
    DataNumbersComponent,
    SampleComponent,
    EssayComponent,
    PostsComponent,
    ContactComponent,
    FooterOfficeComponent,
    FooterCopyrightComponent,
    ConsultancyComponent,
    TabsFormsComponent,
    CertificationComponent,
    ClientComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    NgAnimatedCounterModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxGoogleAnalyticsModule.forRoot('UA-115053014-1'),
  ],
  providers: [BreakpointObserverService],
  bootstrap: [AppComponent],
})
export class AppModule {}
