import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  faCheck,
  faCircleNotch,
  faDotCircle,
} from '@fortawesome/free-solid-svg-icons';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

@Component({
  selector: 'essay',
  templateUrl: './essay.component.html',
  styleUrls: ['./essay.component.scss'],
})
export class EssayComponent implements OnInit {
  @Output() startEndEssay = new EventEmitter();
  @Input() mobile: boolean = false;

  iconCheck = faCheck;
  iconCircle = faCircleNotch;
  iconDotCircle = faDotCircle;
  changeImgs = true;

  list = [
    {
      label: 'Ictiofauna*',
      check: true,
      path: 'ictiofauna',
      img: [
        'ictiofauna-1.jpg',
        'ictiofauna-2.jpg',
        'ictiofauna-3.jpg',
        'ictiofauna-4.jpg',
        'ictiofauna-5.jpg',
        'ictiofauna-6.jpg',
        'ictiofauna-7.jpg',
        'ictiofauna-8.jpg',
        'ictiofauna-9.jpg',
        'ictiofauna-10.jpg',
        'ictiofauna-11.jpg',
        'ictiofauna-12.jpg',
        'ictiofauna-13.jpg',
      ],
      id: '1',
      list: [
        'Identificação taxonômica*',
        'Biometria*',
        'Análise estomacal',
        'Sexagem',
        'Maturação Gonadal',
      ],
    },
    {
      label: 'Zooplâncton*',
      check: false,
      path: 'zooplancton',
      img: [
        'zooplancton-1.jpg',
        'zooplancton-2.jpg',
        'zooplancton-3.jpg',
        'zooplancton-4.jpg',
        'zooplancton-5.jpg',
        'zooplancton-6.jpg',
      ],
      id: '2',
      list: ['Identificação e quantificação'],
    },
    {
      label: 'Invertebrados Bentônicos*',
      check: false,
      path: 'invertebrados-bentonicos',
      img: [
        'invertebrados-bentonicos-1.jpg',
        'invertebrados-bentonicos-2.jpg',
        'invertebrados-bentonicos-3.jpg',
        'invertebrados-bentonicos-4.jpg',
        'invertebrados-bentonicos-5.jpg',
        'invertebrados-bentonicos-6.jpg',
        'invertebrados-bentonicos-7.jpg',
        'invertebrados-bentonicos-8.jpg',
      ],
      id: '3',
      list: [
        'Identificação e quantificação',
        'Identificação individualizada de Malacofauna',
        'Identificação individualizada de Carcinofauna',
        'Identificação e quantificação de Bioindicadores Ambientais',
      ],
    },
    {
      label: 'Ictioplâncton*',
      check: false,
      path: 'ictioplancton',
      img: [
        'ictioplancton-1.jpg',
        'ictioplancton-2.jpg',
        'ictioplancton-3.jpg',
      ],
      id: '4',
      list: ['Identificação e quantificação'],
    },
    {
      label: 'Salinidade Intersticial*',
      check: false,
      path: 'salinidade-intersticial',
      img: [
        'salinidade-intersticial-1.jpg',
        'salinidade-intersticial-2.jpg',
        'salinidade-intersticial-3.jpg',
        'salinidade-intersticial-4.jpg',
      ],
      id: '5',
      list: [
        'Determinação do teor da salinidade da água intersticial por metodologia própria',
      ],
    },
    {
      label: 'Fitoplâncton',
      check: false,
      path: 'fitoplancton',
      img: ['fitoplancton-1.jpg', 'fitoplancton-2.jpg', 'fitoplancton-3.jpg'],
      id: '6',
      list: ['Identificação e quantificação'],
    },
    {
      label: 'Macrófitas Aquáticas',
      check: false,
      path: 'macrofita',
      img: [
        'macrofita-1.jpg',
        'macrofita-2.jpg',
        'macrofita-3.jpg',
        'macrofita-4.jpg',
        'macrofita-5.jpg',
        'macrofita-6.jpg',
        'macrofita-7.jpg',
        'macrofita-8.jpg',
      ],
      id: '7',
      list: ['Identificação', 'Biomassa'],
    },
    {
      label: 'Foraminíferos',
      check: false,
      path: 'foraminiferos',
      img: [
        'foraminiferos-1.jpg',
        'foraminiferos-2.jpg',
        'foraminiferos-3.jpg',
        'foraminiferos-4.jpg',
      ],
      id: '10',
      list: ['Identificação e quantificação'],
    },
    {
      label: 'Granulometria',
      check: false,
      path: 'granulometria',
      img: [
        'granulometria-1.jpg',
        'granulometria-2.jpg',
        'granulometria-3.jpg',
        'granulometria-4.jpg',
        'granulometria-5.jpg',
      ],
      id: '11',
      list: [
        'Separação em porção de finos e grossos por metodologia CETESB L6.160/1995',
        'Separação por peneiramento em porções de finos e grossos por metodologia ABNT NBR 7181:2016',
      ],
    },
    {
      label: 'Dados Ambientais',
      check: false,
      path: 'dados-ambientais',
      img: [
        'dados-ambientais-1.jpg',
        'dados-ambientais-2.jpg',
        'dados-ambientais-3.jpg',
        'dados-ambientais-4.jpg',
      ],
      id: '12',
      list: [
        'Determinação de parâmetros físico-químicos realizados em campo com Medidor Multiparâmetros de alta precisão ',
        'Temperatura*',
        'pH*',
        'Condutividade*',
        'Salinidade*',
        'Sólidos Totais Dissolvidos*',
        'Oxigênio Dissolvido*',
        'Turbidez*',
        'Transparência*',
        'Profundidade*',
        'Potencial de Oxi-Redução*',
      ],
    },
    {
      label: 'Morfometria Foliar',
      check: false,
      path: 'morfometria',
      img: ['morfometria-1.jpg', 'morfometria-2.jpg'],
      id: '13',
      list: [
        'Aferição de parâmetros biométricos;',
        'Teor de umidade;',
        'Características morfológicas de folhas para monitoramento do componente vegetal;',
      ],
    },
  ];

  objContent: any = {};

  @ViewChild('essay', { static: false }) essay: ElementRef = {} as ElementRef;
  @ViewChild('essayTitle', { static: false }) essayTitle: ElementRef =
    {} as ElementRef;

  constructor(private $gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.objContent = this.list[0];

    this.checkItem(this.list[0]);
  }

  ngAfterViewInit() {
    let end =
      this.essayTitle.nativeElement.offsetTop +
      this.essay.nativeElement.getBoundingClientRect().height;
    let start = this.essayTitle.nativeElement.offsetTop - 140;

    if (start && end) this.startEndEssay.emit({ start, end });
  }

  changeSwiper() {
    Swiper.use([Navigation, Pagination, Autoplay]);
    const swiper = new Swiper('.swiper-img', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

  checkItem(item: any) {
    this.objContent = {};
    this.list.map((obj: any) => (obj.check = false));
    item.check = true;

    let findItem = this.list.find((obj: any) => obj.id == item.id);
    this.objContent = findItem;

    if (this.essayTitle.nativeElement && this.mobile) {
      window.scrollTo({
        top: this.essayTitle.nativeElement.offsetTop - 170,
        behavior: 'smooth',
      });
    }

    this.changeImgs = false;
    setTimeout(() => {
      this.changeImgs = true;
    }, 50);

    setTimeout(() => {
      this.changeSwiper();
    }, 50);

    this.$gaService.event('ensaios', 'click', item.label);
  }
}
