<section class="posts">
  <div class="container position-relative">
    <div class="testimonials-slider swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of list">
          <div class="testimonial-item">
            <img
              src="assets/img/posts/{{ item.img }}"
              class="testimonial-img"
              [alt]="item.name"
            />
            <h3>{{ item.name }}</h3>
            <h4>{{ item.office }}</h4>
            <p>
              <fa-icon [icon]="iconTextLeft" class="icon-left"></fa-icon>
              {{ item.text }}
              <fa-icon [icon]="iconTextRight" class="icon-right"></fa-icon>
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</section>
