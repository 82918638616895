import { fromEvent, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';

const QUERY: Map<string, string> = new Map([
  ['xl', '(min-width: 1200px)'],
  ['lg', '(min-width: 992px)'],
  ['md', '(min-width: 768px)'],
  ['sm', '(min-width: 576px)'],
  ['xs', '(min-width: 0px)'],
]);

export class BreakpointObserverService {
  private _size$: Observable<string>;

  constructor() {
    this._size$ = fromEvent(window, 'resize').pipe(
      startWith(this._getScreenSize()),
      map(() => {
        return this._getScreenSize();
      }),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  public get size$(): Observable<string> {
    return this._size$;
  }

  private _getScreenSize(): string {
    const [[newSize = 'never']] = Array.from(QUERY.entries()).filter(
      ([size, MediaQuery]) => window.matchMedia(MediaQuery).matches
    );

    return newSize;
  }
}
