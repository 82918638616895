import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  faBars,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'header-top',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() scrollTop = false;
  @Input() scroll = 0;
  @Input() menuClickName = '';
  @Input() mobile = false;
  @Input() scrollMenuCheck = {
    init: { start: 0, end: 0 },
    about: { start: 0, end: 0 },
    numbers: { start: 0, end: 0 },
    sample: { start: 0, end: 0 },
    essay: { start: 0, end: 0 },
    consultancy: { start: 0, end: 0 },
    client: { start: 0, end: 0 },
    certification: { start: 0, end: 0 },
    contact: { start: 0, end: 0 },
  };
  @Output() changeMenu = new EventEmitter();

  iconMenu = faBars;

  checkInit = true;
  checkAbout = false;
  checkSample = false;
  checkEssay = false;
  checkTeam = false;
  checkConsultancy = false;
  checkContact = false;
  checkCertification = false;
  checkClient = false;

  menuChange = false;
  setaMenu = faChevronRight;
  btClose = faTimes;
  closeMenu = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    //marcação do menu
    // inicio
    if (
      this.scroll >= this.scrollMenuCheck.init.start &&
      this.scroll < this.scrollMenuCheck.init.end
    ) {
      this.resetMenuItens();
      this.checkInit = true;
    }
    // sobre nos
    else if (
      this.scroll > this.scrollMenuCheck.about.start - 100 &&
      this.scroll < this.scrollMenuCheck.about.end - 350
    ) {
      this.resetMenuItens();
      this.checkAbout = true;
    }
    // numeros
    else if (
      this.scroll > this.scrollMenuCheck.numbers.start - 300 &&
      this.scroll < this.scrollMenuCheck.numbers.end - 750 //700
    ) {
      this.resetMenuItens();
      this.changeMenu.emit({ numbers: true });
    }
    // amostragem
    else if (
      this.scroll > this.scrollMenuCheck.sample.start - 250 &&
      this.scroll < this.scrollMenuCheck.sample.end - 250
    ) {
      this.resetMenuItens();
      this.checkSample = true;
    }
    // ensaios
    else if (
      this.scroll > this.scrollMenuCheck.essay.start - 100 &&
      this.scroll < this.scrollMenuCheck.essay.end - 150
    ) {
      this.resetMenuItens();
      this.checkEssay = true;
    }
    // consultoria
    else if (
      this.scroll > this.scrollMenuCheck.consultancy.start - 100 &&
      this.scroll < this.scrollMenuCheck.consultancy.end - 150
    ) {
      this.resetMenuItens();
      this.checkConsultancy = true;
    }
    // clientes
    else if (
      this.scroll > this.scrollMenuCheck.client.start - 150 &&
      this.scroll < this.scrollMenuCheck.client.end
    ) {
      this.resetMenuItens();
      this.checkClient = true;
    }
    // certificados
    else if (
      this.scroll > this.scrollMenuCheck.certification.start + 200 &&
      this.scroll < this.scrollMenuCheck.certification.end - 250
    ) {
      this.resetMenuItens();
      this.checkCertification = true;
    }
    // contato
    else if (
      this.scroll > this.scrollMenuCheck.contact.start + 400 &&
      this.scroll < this.scrollMenuCheck.contact.end
    ) {
      this.resetMenuItens();
      this.checkContact = true;
    }

    if (changes) {
      if (changes.menuClickName) {
        if (this.menuClickName != '') {
          this.goMenu(this.menuClickName);
        }
      }
    }
  }

  resetMenuItens() {
    this.checkInit = false;
    this.checkAbout = false;
    this.checkSample = false;
    this.checkEssay = false;
    this.checkTeam = false;
    this.checkConsultancy = false;
    this.checkClient = false;
    this.checkContact = false;
    this.checkCertification = false;

    this.changeMenu.emit({ numbers: false });
  }

  goMenu(item: string) {
    let valueTop = 0;
    let valueTopMobile = 0;

    if (item == 'init') {
      valueTop = 0;
    } else if (item == 'about') {
      valueTop = this.scrollMenuCheck.about.start - 20;
      valueTopMobile = this.scrollMenuCheck.about.start;
    } else if (item == 'sample') {
      valueTop = this.scrollMenuCheck.sample.start - 240;
      valueTopMobile = this.scrollMenuCheck.sample.start - 260;
    } else if (item == 'essay') {
      valueTop = this.scrollMenuCheck.essay.start;
      valueTopMobile = this.scrollMenuCheck.essay.start + 200;
    } else if (item == 'consultancy') {
      valueTop = this.scrollMenuCheck.consultancy.start;
      valueTopMobile = this.scrollMenuCheck.consultancy.start + 500;
    } else if (item == 'client') {
      valueTop = this.scrollMenuCheck.client.start;
      valueTopMobile = this.scrollMenuCheck.client.start + 740;
    } else if (item == 'certification') {
      valueTop = this.scrollMenuCheck.certification.start + 240;
      valueTopMobile = this.scrollMenuCheck.certification.start + 520;
    } else if (item == 'contact') {
      valueTop = this.scrollMenuCheck.contact.start + 470;
      valueTopMobile = this.scrollMenuCheck.contact.start + 750;
    }

    this.closeMenu = true;
    this.menuChange = false;
    window.scrollTo({
      top: this.mobile ? valueTopMobile : valueTop,
      behavior: 'smooth',
    });
  }
}
