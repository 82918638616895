<div class="about" #about>
  <div class="container">
    <h1 class="title-default" #aboutTitle>QUEM SOMOS</h1>

    <section class="row content-text">
      <div class="col-lg-12">
        <p>
          Somos uma empresa sediada em Santos-SP e com ampla atuação em todo o
          território nacional.
        </p>
        <p>
          No mercado ambiental há mais de 10 anos, oferecemos produtos e
          serviços com alta qualidade técnica, desenvolvidos de forma exclusiva
          para a sua empresa.
        </p>
        <p>
          Resultado do trabalho em conjunto de professores universitários,
          profissionais especializados e estudantes, a Aquática é um centro de
          referência nacional em consultoria ambiental, atuando com
          comprometimento ao atendimento da norma ABNT NBR ISO/IEC 17025, dos
          clientes, das autoridades regulamentadoras e organizações que fornecem
          reconhecimento.
        </p>

        <i
          >Contamos com uma ampla estrutura, com equipamentos e laboratórios
          próprios, buscando sempre atender com excelência nossos clientes e
          fornecedores.
        </i>
      </div>

      <div class="col-lg-12 content-itens">
        <ul>
          <li>
            <fa-icon [icon]="iconFile"></fa-icon>
            <div>
              <h3>Visão</h3>
              <p>
                Reduzir o tempo de execução dos processos internos da aquática.
              </p>
            </div>
          </li>
          <li>
            <fa-icon [icon]="iconCube"></fa-icon>
            <div>
              <h3>Missão</h3>
              <p>
                Oferecer conhecimento técnico ambiental com qualidade e
                dinamismo proporcionando mais tempo a nossos clientes, para que
                possam maximizar seus investimentos.
              </p>
            </div>
          </li>
          <li>
            <fa-icon [icon]="iconImages"></fa-icon>
            <div>
              <h3>Valores</h3>
              <p>
                Comprometimento, cooperação, foco em resultados, ética e base
                cientifica. Engajamento no cumprimento das atividades,
                valorização do trabalho em conjunto, com colaboração e
                interdisciplinaridade.
              </p>
            </div>
          </li>
          <li>
            <fa-icon [icon]="iconShield"></fa-icon>
            <div>
              <h3>Política</h3>
              <p>
                ética e legalidade; base científica e capacitação técnica;
                ambiente de trabalho colaborativo; clareza e transparência;
                comprometimento com o cliente; discrição; sigilo e
                confidencialidade
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</div>
