import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  @Output() startEndClient = new EventEmitter();

  @ViewChild('client', { static: false }) client: ElementRef = {} as ElementRef;
  @ViewChild('clientTitle', { static: false })
  clientTitle: ElementRef = {} as ElementRef;

  iconCheck = faCheck;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let end =
      this.clientTitle.nativeElement.offsetTop +
      this.client.nativeElement.getBoundingClientRect().height;
    let start = this.clientTitle.nativeElement.offsetTop - 140;

    if (start && end) this.startEndClient.emit({ start, end });
  }
}
