import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tabs-forms',
  templateUrl: './tabs-forms.component.html',
  styleUrls: ['./tabs-forms.component.scss'],
})
export class TabsFormsComponent implements OnInit {
  sentMessage = false;
  errorMessage = false;

  tabs = [
    { label: 'Contato', check: true },
    {
      label: 'Trabalhe Conosco',
      check: false,
    },
    {
      label: 'Seja um Fornecedor',
      check: false,
    },
  ];

  listState = [
    { label: 'Acre', value: 'Acre' },
    { label: 'Alagoas', value: 'Alagoas' },
    { label: 'Amapá', value: 'Amapá' },
    { label: 'Amazonas', value: 'Amazonas' },
    { label: 'Bahia', value: 'Bahia' },
    { label: 'Ceará', value: 'Ceará' },
    { label: 'Distrito Federal', value: 'Distrito Federal' },
    { label: 'Espírito Santo', value: 'Espírito Santo' },
    { label: 'Goiás', value: 'Goiás' },
    { label: 'Maranhão', value: 'Maranhão' },
    { label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' },
    { label: 'Mato Grosso', value: 'Mato Grosso' },
    { label: 'Minas Gerais', value: 'Minas Gerais' },
    { label: 'Pará', value: 'Pará' },
    { label: 'Paraíba', value: 'Paraíba' },
    { label: 'Paraná', value: 'Paraná' },
    { label: 'Pernambuco', value: 'Pernambuco' },
    { label: 'Piauí', value: 'Piauí' },
    { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
    { label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
    { label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' },
    { label: 'Rondônia', value: 'Rondônia' },
    { label: 'Roraima', value: 'Roraima' },
    { label: 'Santa Catarina', value: 'Santa Catarina' },
    { label: 'São Paulo', value: 'São Paulo' },
    { label: 'Sergipe', value: 'Sergipe' },
    { label: 'Tocantins', value: 'Tocantins' },
  ];

  listOffice = [
    { label: 'Administrativo', value: 'Administrativo' },
    { label: 'Biólogo', value: 'Biólogo' },
    { label: 'Comercial', value: 'Comercial' },
    { label: 'Comunicação', value: 'Comunicação' },
    { label: 'Editoração', value: 'Editoração' },
    { label: 'Engenheiro Ambiental', value: 'Engenheiro Ambiental' },
    { label: 'Financeiro', value: 'Financeiro' },
    { label: 'Geólogo', value: 'Geólogo' },
    { label: 'Gerência de Projetos', value: 'Gerência de Projetos' },
    { label: 'Hidrólogo', value: 'Hidrólogo' },
    { label: 'Logística', value: 'Logística' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Oceanógrafo', value: 'Oceanógrafo' },
    { label: 'Qualidade', value: 'Qualidade' },
    { label: 'Químico', value: 'Químico' },
    { label: 'Técnico em Meio Ambiente', value: 'Técnico em Meio Ambiente' },
    { label: 'Técnico em Química', value: 'Técnico em Química' },
    {
      label: 'Técnico em Saneamento Ambiental',
      value: 'Técnico em Saneamento Ambiental',
    },
    { label: 'TI - Cientista de Dados', value: 'TI - Cientista de Dados' },
    {
      label: 'TI - Gerente de Desenvolvimento',
      value: 'TI - Gerente de Desenvolvimento',
    },
    { label: 'Outros', value: 'Outros' },
  ];

  listSchooling = [
    { label: 'Ensino Fundamental', value: 'Ensino Fundamental' },
    { label: 'Ensino Médio', value: 'Ensino Médio' },
    { label: 'Ensino Superior', value: 'Ensino Superior' },
    { label: 'Pós-Graduação', value: 'Pós-Graduação' },
  ];

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if (window.location.href.split('?')[1] == 'enviado=') {
        window.scrollTo({ top: 8633, behavior: 'smooth' });
        this.sentMessage = true;
      }
      if (window.location.href.split('?')[1] == 'error=') {
        window.scrollTo({ top: 8633, behavior: 'smooth' });
        this.errorMessage = true;
      }
    }, 2400);
  }

  tabsChange(item: any) {
    this.tabs.map((obj: any) => (obj.check = false));

    item.check = true;
  }
}
