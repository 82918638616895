<section class="client" #client>
  <div class="container">
    <h1 class="title-default" #clientTitle>Alguns de nossos clientes</h1>
    <h2>Conheça alguns trabalhos que a Aquática já realizou:</h2>

    <ul class="list-img">
      <li>
        <div>
          <img src="assets/img/clientes/appa.png" alt="appa" title="appa" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/celse.png" alt="celse" title="celse" />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/consimares.png"
            alt="consimares"
            title="consimares"
          />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/daee.png" alt="daee" title="daee" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/dnit.png" alt="dnit" title="dnit" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/dta.png" alt="dta" title="dta" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/erm.png" alt="erm" title="erm" />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/estado-sp.png"
            alt="estado-sp"
            title="estado-sp"
          />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/hydro.png" alt="hydro" title="hydro" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/mpf.png" alt="mpf" title="mpf" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/oas.png" alt="oas" title="oas" />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/petrobras.png"
            alt="petrobras"
            title="petrobras"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/polifonicas.png"
            alt="polifonicas"
            title="polifonicas"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/porto-santos.png"
            alt="porto-santos"
            title="porto-santos"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/porto-sao-sebastiao.png"
            alt="porto-sao-sebastiao"
            title="porto-sao-sebastiao"
          />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/prime.png" alt="prime" title="prime" />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/renova.png"
            alt="renova"
            title="renova"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/renova-energia.png"
            alt="renova-energia"
            title="renova-energia"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/sabesp.png"
            alt="sabesp"
            title="sabesp"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/suzano.png"
            alt="suzano"
            title="suzano"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/tetra+.png"
            alt="tetra+"
            title="tetra+"
          />
        </div>
      </li>
      <li>
        <div>
          <img
            src="assets/img/clientes/tetra-tech.png"
            alt="tetra-tech"
            title="tetra-tech"
          />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/vale.png" alt="vale" title="vale" />
        </div>
      </li>
      <li>
        <div>
          <img src="assets/img/clientes/walm.png" alt="walm" title="walm" />
        </div>
      </li>
    </ul>

    <h3 class="mt-5 mb-3">
      Conheça alguns trabalhos que a Aquática já realizou
    </h3>
    <ul class="list-txt">
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Estudo e Relatório de Impacto Ambiental (EIA/RIMA) para derrocamento de
        hidrovia.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Execução de Programa de Monitoramento da Ictiofauna na fase de
        implantação de barragens hídricas.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        <p>
          Execução de Programa de Monitoramento da Ictiofauna e Comunidade
          Aquática para instalação de linha de transmissão e terminal de gás
          natural offshore.
        </p>
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Estudo de Componente Indígena para a avaliação da qualidade do pescado
        (estudos de identificação, biometria e bioacumulação).
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Estudo de zooplâncton para diagnóstico de meio biótico para implantação
        de estrutura de exploração de petróleo (OnShore).
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Desenvolvimento de Programa de Caracterização da Biodiversidade da Fauna
        Silvestre em atendimento à legislação local.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Estudo e Relatório de Impacto Ambiental (EIA/RIMA) – Meio Biótico –
        Comunidade Aquática – para instalação de Usina de Energia.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Estudo e Relatório de Impacto Ambiental (EIA/RIMA) – Meio Biótico –
        Comunidade Aquática – para instalação de Planta de Tratamento de
        Resíduos.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Amostragem de matrizes ambientais e ensaios de dados ambientais para
        compor Programas de Monitoramento da Atividade de Dragagem de Portos.
      </li>
      <li>
        <fa-icon [icon]="iconCheck"></fa-icon>
        Execução de Programas de Monitoramento Ambiental para a implantação de
        operação das obras de Transposições Fluviais.
      </li>
    </ul>
  </div>
</section>
