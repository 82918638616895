import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'footer-copyright',
  templateUrl: './footer-copyright.component.html',
  styleUrls: ['./footer-copyright.component.scss'],
})
export class FooterCopyrightComponent implements OnInit {
  year: Number = 0;

  constructor() {}

  ngOnInit(): void {
    let date = new Date();

    this.year = date.getFullYear();
  }
}
