<section class="contact" #contact>
  <h1 class="title-default" #contactTitle>Contato</h1>

  <div class="map">
    <iframe
      style="border: 0; width: 100%; height: 350px"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3646.1308186149695!2d-46.32735138501463!3d-23.95581378448855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce030a8b737bad%3A0x552d88de9868b73d!2sAv.%20Conselheiro%20Rodrigues%20Alves%2C%20481%20-%20Macuco%2C%20Santos%20-%20SP%2C%2011015-203!5e0!3m2!1spt-BR!2sbr!4v1642275373634!5m2!1spt-BR!2sbr"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-4 card-contact">
        <div class="icon">
          <fa-icon [icon]="iconMarker" class="icon"></fa-icon>
        </div>
        <div class="content-text">
          <h3>Localização:</h3>
          <p>Avenida Conselheiro Rodrigues Alves</p>
          <p>nº 481 - Santos/SP</p>
        </div>
      </div>
      <div class="col-lg-4 card-contact">
        <div class="icon">
          <fa-icon [icon]="iconEmail" class="icon"></fa-icon>
        </div>
        <div class="content-text">
          <h3>Email:</h3>
          <p><a href="mailto:contato@aqtca.com.br">contato@aqtca.com.br</a></p>
        </div>
      </div>
      <div class="col-lg-4 card-contact">
        <div class="icon">
          <fa-icon [icon]="iconPhone" class="icon"></fa-icon>
        </div>
        <div class="content-text">
          <h3>Telefones:</h3>
          <p>(13) 3223-8410</p>
        </div>
      </div>
    </div>

    <tabs-forms></tabs-forms>
  </div>
</section>
