import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss'],
})
export class CertificationComponent implements OnInit {
  @Output() startEndCertification = new EventEmitter();

  @ViewChild('certification', { static: false }) certification: ElementRef =
    {} as ElementRef;
  @ViewChild('certificationTitle', { static: false })
  certificationTitle: ElementRef = {} as ElementRef;

  iconCertification = faFileAlt;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let end =
      this.certificationTitle.nativeElement.offsetTop +
      this.certification.nativeElement.getBoundingClientRect().height;
    let start = this.certificationTitle.nativeElement.offsetTop + 90;

    if (start && end) this.startEndCertification.emit({ start, end });
  }
}
