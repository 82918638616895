<header [ngClass]="{ 'header-scrolled': scrollTop }">
  <div class="container">
    <div class="content-max">
      <fa-icon
        [icon]="iconMenu"
        class="iconMenu"
        (click)="menuChange = !menuChange; closeMenu = false"
      ></fa-icon>

      <div class="logo">
        <img src="./assets/img/aquatica.png" />
      </div>

      <div class="selo">
        <a
          href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL1594.pdf"
          target="_blank"
          ><img src="./assets/img/selo.png"
        /></a>
      </div>

      <nav
        [ngClass]="{
          'menu-mobile': menuChange,
          'menu-close': closeMenu
        }"
      >
        <a [ngClass]="{ marcado: checkInit }" (click)="goMenu('init')">
          <fa-icon [icon]="setaMenu"></fa-icon>Início
        </a>
        <a [ngClass]="{ marcado: checkAbout }" (click)="goMenu('about')">
          <fa-icon [icon]="setaMenu"></fa-icon>Quem Somos
        </a>
        <a [ngClass]="{ marcado: checkSample }" (click)="goMenu('sample')">
          <fa-icon [icon]="setaMenu"></fa-icon>Amostragem
        </a>
        <a [ngClass]="{ marcado: checkEssay }" (click)="goMenu('essay')">
          <fa-icon [icon]="setaMenu"></fa-icon>Ensaios
        </a>
        <a
          [ngClass]="{ marcado: checkConsultancy }"
          (click)="goMenu('consultancy')"
        >
          <fa-icon [icon]="setaMenu"></fa-icon>Consultoria
        </a>
        <a [ngClass]="{ marcado: checkClient }" (click)="goMenu('client')">
          <fa-icon [icon]="setaMenu"></fa-icon>Clientes
        </a>
        <a
          [ngClass]="{ marcado: checkCertification }"
          (click)="goMenu('certification')"
          ><fa-icon [icon]="setaMenu"></fa-icon>Certificação</a
        >
        <a [ngClass]="{ marcado: checkContact }" (click)="goMenu('contact')">
          <fa-icon [icon]="setaMenu"></fa-icon>Contato
        </a>

        <fa-icon
          [icon]="btClose"
          class="bt-close"
          (click)="menuChange = false; closeMenu = true"
        ></fa-icon>
      </nav>
    </div>
  </div>
</header>
