<section class="consultancy" #consultancy>
  <div class="container">
    <h1 class="title-default" #consultancyTitle>Consultoria</h1>

    <div class="row">
      <div class="col-lg-12 content-text">
        <p>
          A Aquática possui ampla experiência na elaboração de relatórios e
          estudos ambientais com refinadas técnicas desde a solicitação de
          licenças de implantação até a fase de operação, atuando em todas as
          etapas de legalização ambiental dos mais variados tipos de
          empreendimento. Trabalhando especialmente com, mas não limitando-se à,
          fauna e flora aquática em ambientes dulcícolas, marinhos e estuarinos,
          contamos com um corpo técnico multidisciplinar o qual é altamente
          capacitado a atuar na elaboração e realização de amplos estudos:
        </p>

        <div class="row">
          <div class="col-lg-3 mb-4">
            <div class="card-icon">
              <div class="icone-img">
                <img src="assets/img/icones/sensoriamento.png" />
              </div>
              <h3>Sensoriamento remoto</h3>
            </div>
          </div>
          <div class="col-lg-3 mb-4">
            <div class="card-icon">
              <div class="icone-img">
                <img src="assets/img/icones/mapas.png" />
              </div>
              <h3>Elaboração de mapas</h3>
            </div>
          </div>
          <div class="col-lg-3 mb-4">
            <div class="card-icon">
              <fa-icon [icon]="iconBox2" class="icon"></fa-icon>
              <h3>Modelagem</h3>
            </div>
          </div>
          <div class="col-lg-3 mb-4">
            <div class="card-icon">
              <fa-icon [icon]="iconBox3" class="icon"></fa-icon>
              <h3>Projetos</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="testimonials-slider swiper-consultoria-img swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="testimonial-item">
                <img
                  src="assets/img/consultoria/consultoria-1.jpg"
                  class="img"
                />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="testimonial-item">
                <img
                  src="assets/img/consultoria/consultoria-2.jpg"
                  class="img"
                />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="testimonial-item">
                <img
                  src="assets/img/consultoria/consultoria-3.jpg"
                  class="img"
                />
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-img"></div>
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="content-img">
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span
              >Estudos ambientais para projeção e elaboração de
              empreendimentos;</span
            >
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span>Avaliação Ambiental Estratégica e Integrada;</span>
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span
              >Estudo de Impacto Ambiental e Relatório de Impacto
              Ambiental;</span
            >
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span>Plano Básico Ambiental;</span>
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span>Licenciamento Ambiental;</span>
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span>Planejamento, Gerenciamento e Monitoramento Ambiental;</span>
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span
              >Assessoria técnica para elaboração de Projetos e Estudos
              Ambientais (EIA, RCA, PCA, DIA, entre outros);</span
            >
          </li>
          <li>
            <fa-icon [icon]="iconCheck"></fa-icon>
            <span
              >Participação técnica em reuniões, audiências, auditorias e
              devolutivas;</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
