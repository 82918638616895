import { Component, Renderer2 } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { BreakpointObserverService } from 'src/app/service/mobile-resize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'aquatica';
  scrollHeader = false;
  iconUp = faArrowUp;
  scrollTop = 0;
  activeNumbers = false;
  menuClickName = '';
  size$: Observable<string> = new Observable();
  isMobile = false;

  scrollMenuCheck = {
    init: { start: 0, end: 0 },
    about: { start: 0, end: 0 },
    numbers: { start: 0, end: 0 },
    sample: { start: 0, end: 0 },
    essay: { start: 0, end: 0 },
    consultancy: { start: 0, end: 0 },
    client: { start: 0, end: 0 },
    certification: { start: 0, end: 0 },
    contact: { start: 0, end: 0 },
  };

  constructor(
    private render2: Renderer2,
    private _breakpointObserverService: BreakpointObserverService
  ) {
    this.render2.listen('window', 'scroll', (e) => {
      if (window.scrollY > 95) {
        this.scrollHeader = true;
      } else {
        this.scrollHeader = false;
      }

      this.scrollTop = window.scrollY;
    });

    this.size$ = this._breakpointObserverService.size$;

    this.size$.subscribe((data) => {
      this.isMobile = false;

      if (data == 'xs' || data == 'sm') {
        this.isMobile = true;
      }
    });
  }

  scroolTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  changeMenu(event: any) {
    if (event.numbers) {
      this.activeNumbers = true;
    } else {
      this.activeNumbers = false;
    }
  }

  changeMenuClick(event: any) {
    this.menuClickName = 'tes';
    this.menuClickName = event;
  }

  startEndAbout(event: any) {
    if (event) {
      this.scrollMenuCheck.about = event;
      this.scrollMenuCheck.init.end = event.start - 150;
    }
  }

  startEndSample(event: any) {
    if (event) {
      this.scrollMenuCheck.sample = event;
    }
  }

  startEndNumbers(event: any) {
    if (event) {
      this.scrollMenuCheck.numbers = event;
    }
  }

  startEndEssay(event: any) {
    if (event) {
      this.scrollMenuCheck.essay = event;
    }
  }

  startEndConsultancy(event: any) {
    if (event) {
      this.scrollMenuCheck.consultancy = event;
    }
  }

  startEndContact(event: any) {
    if (event) {
      this.scrollMenuCheck.contact = event;
    }
  }

  startEndCertification(event: any) {
    if (event) {
      this.scrollMenuCheck.certification = event;
    }
  }

  startEndClient(event: any) {
    if (event) {
      this.scrollMenuCheck.client = event;
    }
  }
}
