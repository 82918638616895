<section class="essay" #essay>
  <div class="container">
    <h1 class="title-default" #essayTitle>Ensaios</h1>

    <div class="row">
      <div class="col-lg-6 col-xs-12 card-am">
        <div class="card-img" *ngIf="objContent.img.length == 1">
          <img
            src="./assets/img/ensaio/{{ objContent.path }}/{{
              objContent.img[0]
            }}"
            alt="{{ objContent.label }}"
            title="{{ objContent.label }}"
          />
        </div>

        <div
          class="testimonials-slider swiper-img swiper"
          *ngIf="changeImgs && objContent.img.length > 1"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let itemImg of objContent.img">
              <div class="testimonial-item">
                <img
                  src="assets/img/ensaio/{{ objContent.path }}/{{ itemImg }}"
                  class="img"
                  alt="{{ objContent.label }}"
                  title="{{ objContent.label }}"
                />
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-img"></div>
        </div>
      </div>

      <div class="col-lg-6 col-xs-12 card-am">
        <div class="row">
          <div
            class="col-lg-4 card-item"
            *ngFor="let itemTxt of list"
            [ngClass]="{ check: itemTxt.check }"
            (click)="checkItem(itemTxt)"
          >
            <fa-icon
              [icon]="itemTxt.check ? iconDotCircle : iconCircle"
            ></fa-icon>
            <span>{{ itemTxt.label }}</span>
          </div>

          <p class="legend">*Serviços Acreditados ABNT ISO/NBR 17025:2017</p>

          <ul class="content-img">
            <li *ngFor="let item of objContent.list">
              <fa-icon [icon]="iconCheck"></fa-icon> <span>{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
