import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  faCheckDouble,
  faFileAlt,
  faImages,
  faCube,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  @Output() startEndAbout = new EventEmitter();

  iconCheck = faCheckDouble;
  iconFile = faFileAlt;
  iconImages = faImages;
  iconCube = faCube;
  iconShield = faShieldAlt;

  @ViewChild('about', { static: false }) about: ElementRef = {} as ElementRef;
  @ViewChild('aboutTitle', { static: false }) aboutTitle: ElementRef =
    {} as ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let end =
      this.aboutTitle.nativeElement.offsetTop +
      this.about.nativeElement.getBoundingClientRect().height;
    let start = this.aboutTitle.nativeElement.offsetTop - 140;

    if (start && end) this.startEndAbout.emit({ start, end });
  }
}
