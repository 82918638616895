import { Component, OnInit } from '@angular/core';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  iconTextLeft = faQuoteLeft;
  iconTextRight = faQuoteRight;

  list = [
    {
      img: 'testimonials-1.jpg',
      name: 'Saul Goodman',
      office: 'Ceo &amp; Founder',
      text: 'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.'
    },
    {
      img: 'testimonials-1.jpg',
      name: 'Saul Goodman',
      office: 'Ceo &amp; Founder',
      text: 'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.'
    },
    {
      img: 'testimonials-1.jpg',
      name: 'Saul Goodman',
      office: 'Ceo &amp; Founder',
      text: 'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.'
    },
    {
      img: 'testimonials-1.jpg',
      name: 'Saul Goodman',
      office: 'Ceo &amp; Founder',
      text: 'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.'
    },
    {
      img: 'testimonials-1.jpg',
      name: 'Saul Goodman',
      office: 'Ceo &amp; Founder',
      text: 'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.'
    }
  ]
  
  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    Swiper.use([Navigation, Pagination, Autoplay]);
    const swiper = new Swiper('.testimonials-slider', 
    {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }

}
