import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  NgbCarousel,
  NgbSlideEvent,
  NgbSlideEventSource,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Output() goMenu = new EventEmitter();

  iconLeft = faChevronLeft;
  iconRight = faChevronRight;
  indexBanner = 0;

  listCarrousel = [
    {
      url: 'assets/img/slide/campo-2.jpg',
      title: 'Bem vindo a Aquática',
      subTitle:
        'Consultoria Ambiental especializada em execução e suporte em soluções ambientais.',
    },
    {
      url: 'assets/img/slide/campo-4.jpg',
      title: 'Certificação Inmetro 17025',
      subTitle:
        'Laboratório acreditado pela Coordenação Geral de Acreditação para ensaios e amostragens sob número CRL 1594. Escopo certificado para análises químicas e biológicas em meio ambiente.',
      btn: 'Leia Mais',
      link: 'certification',
    },
    {
      url: 'assets/img/slide/ensaios-1.jpg',
      title: 'Ensaios',
      subTitle:
        'Dispomos de laboratórios devidamente equipados e equipe especializada e atualizada para desenvolver com qualidade ensaios de comunidades aquáticas e químicos de bancada.',
      btn: 'Leia Mais',
      link: 'essay',
    },
  ];

  constructor() {}

  next() {
    if (this.indexBanner <= 1) {
      this.indexBanner = this.indexBanner + 1;
    } else {
      this.indexBanner = 0;
    }
  }

  prev() {
    if (this.indexBanner >= 1) {
      this.indexBanner = this.indexBanner - 1;
    } else {
      this.indexBanner = 2;
    }
  }

  changeBannerBullet(index: any) {
    this.indexBanner = index;
  }

  ngOnInit(): void {
    setInterval(() => {
      this.next();
    }, 7000);
  }

  goMenulink(menu: string) {
    this.goMenu.emit(menu);
  }
}
