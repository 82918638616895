<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-xs-12">
        <h2><img src="./assets/img/aquatica-branco.png" /></h2>

        <p>Avenida Conselheiro Rodrigues Alves nº 481</p>
        <p>Santos/SP</p>

        <p><strong>Telefone:</strong> (13) 3223-8410</p>
        <p>
          <strong>Email:</strong>
          <a href="mailto:contato@aqtca.com.br">contato@aqtca.com.br</a>
        </p>
      </div>
      <div class="col-lg-3 col-6 links">
        <h3>Links úteis</h3>

        <ul>
          <li (click)="goMenu('init')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Início</a>
          </li>
          <li (click)="goMenu('about')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Quem Somos</a>
          </li>
          <li (click)="goMenu('sample')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Amostragem</a>
          </li>
          <li (click)="goMenu('essay')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Ensaios</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-6 links">
        <h3 class="title-empty"></h3>

        <ul>
          <li (click)="goMenu('consultancy')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Consultoria</a>
          </li>
          <li (click)="goMenu('client')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Clientes</a>
          </li>
          <li (click)="goMenu('certification')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Certificação</a>
          </li>
          <li (click)="goMenu('contact')">
            <a><fa-icon [icon]="iconLink"></fa-icon> Contato</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-6 networks">
        <ul>
          <li>
            <a
              href="https://instagram.com/aqtcaconsultoria?utm_medium=copy_link"
              target="_blank"
              ><fa-icon [icon]="iconInstagran"></fa-icon
            ></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/aqtca/" target="_blank"
              ><fa-icon [icon]="iconLinkedin"></fa-icon
            ></a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-6 selo">
        <a
          href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL1594.pdf"
          target="_blank"
          class="selo"
          ><img src="./assets/img/selo.png"
        /></a>
      </div>
    </div>
  </div>
</footer>
