import { Component, OnInit, Input } from '@angular/core';
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss'],
})
export class HeaderInfoComponent implements OnInit {
  @Input() scrollTop = false;

  iconEnvelope = faEnvelope;
  iconPhone = faMobileAlt;
  iconTwitter = faTwitter;
  iconFacebook = faFacebook;
  iconInstagran = faInstagram;
  iconLinkedin = faLinkedin;

  constructor() {}

  ngOnInit(): void {}
}
