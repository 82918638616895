<section class="certification" #certification>
  <div class="container">
    <h1 class="title-default" #certificationTitle>Certificação</h1>

    <div class="content-certification row">
      <div class="certification-card col-lg-6">
        <h2>ISO/IEC 17025:2017</h2>
        <p>
          Possuímos escopo acreditado pela CGCre para realização de amostragem
          de matrizes ambientais e comunidades aquáticas, além de ensaios
          químicos e biológicos em instalações próprias e do cliente. Sendo um
          dos poucos laboratórios certificados para a realização de ensaios de
          ictiofauna e o único certificado para ensaio de salinidade em água
          intersticial com metodologia própria.
        </p>

        <ul>
          <li>
            <fa-icon [icon]="iconCertification"></fa-icon>
            <a
              href="./assets/certificados/certificado-inmetro.pdf"
              target="_blank"
              >Certificado INMETRO</a
            >
          </li>
          <li>
            <fa-icon [icon]="iconCertification"></fa-icon>
            <a href="./assets/certificados/escopo-inmetro.pdf" target="_blank"
              >Escopo INMETRO</a
            >
          </li>
        </ul>
      </div>

      <div class="certification-card col-lg-6">
        <h2>Rede Metrológica do Rio Grande do Sul (RMRS)</h2>
        <p>
          Estamos listados entre os laboratórios reconhecidos pela Rede
          Metrológica do Rio Grande Sul, para amostragem, ensaios químicos e
          biológicos. Atestando nossa qualidade técnica e metrológica.
        </p>

        <ul>
          <li>
            <fa-icon [icon]="iconCertification"></fa-icon>
            <a
              href="./assets/certificados/RMRS-ensaios-biologicos.pdf"
              target="_blank"
              >RMRS Ensaios biológicos</a
            >
          </li>
          <li>
            <fa-icon [icon]="iconCertification"></fa-icon>
            <a href="./assets/certificados/RMRS-amostragem.pdf" target="_blank"
              >RMRS Amostragem</a
            >
          </li>
          <li>
            <fa-icon [icon]="iconCertification"></fa-icon>
            <a
              href="./assets/certificados/RMRS-ensaios-quimicos.pdf"
              target="_blank"
              >RMRS Ensaios químicos</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
