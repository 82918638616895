import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import {
  faCheck,
  faCircleNotch,
  faDotCircle,
} from '@fortawesome/free-solid-svg-icons';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

@Component({
  selector: 'sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
})
export class SampleComponent implements OnInit {
  @Output() startEndSample = new EventEmitter();
  @Input() mobile: boolean = false;

  iconCheck = faCheck;
  iconCircle = faCircleNotch;
  iconDotCircle = faDotCircle;

  listSample = [
    {
      label: 'Águas*',
      check: true,
      path: 'agua',
      img: [
        'amostragem-agua-1.jpg',
        'amostragem-agua-2.jpg',
        'amostragem-agua-3.jpg',
        'amostragem-agua-4.jpg',
        'amostragem-agua-5.jpg',
      ],
      txt: [
        'Água salina',
        'Água tratada',
        'Água intersticial',
        'Água salobra',
        'Água para consumo humano',
        'Água bruta',
        'Água residual',
      ],
      id: '1',
    },
    {
      label: 'Costão Rochoso*',
      check: false,
      path: 'costao-rochoso',
      img: ['costao-rochoso-1.jpg', 'costao-rochoso-2.jpg'],
      txt: [],
      id: 16,
    },
    {
      label: 'Efluentes Domésticos*',
      check: false,
      path: 'efluentes-domesticos',
      img: ['efluentes-domesticos.jpg'],
      txt: [],
      id: 7,
    },
    {
      label: 'Estações de Tratamento de Água*',
      check: false,
      path: 'estacoes-tratamento-agua',
      img: ['estacoes-tratamento-agua.jpg'],
      txt: [],
      id: 11,
    },
    {
      label: 'Estações de Tratamento de Esgoto*',
      check: false,
      path: 'estacoes-tratamento-esgoto',
      img: ['estacoes-tratamento-esgoto.jpg'],
      txt: [],
      id: 12,
    },
    {
      label: 'Fitoplâncton*',
      check: false,
      path: 'fitoplancton',
      img: ['fitoplancton-1.jpg', 'fitoplancton-2.jpg'],
      id: 19,
      txt: [],
    },
    {
      label: 'Foraminíferos',
      check: false,
      path: 'foraminiferos',
      img: [
        'foraminiferos-1.jpg',
        'foraminiferos-2.jpg',
        'foraminiferos-3.jpg',
      ],
      txt: [],
      id: 22,
    },
    {
      label: 'Ictiofauna*',
      check: false,
      path: 'ictiofauna',
      img: [
        'ictiofauna-1.jpg',
        'ictiofauna-2.jpg',
        'ictiofauna-3.jpg',
        'ictiofauna-4.jpg',
        'ictiofauna-5.jpg',
        'ictiofauna-6.jpg',
        'ictiofauna-7.jpg',
        'ictiofauna-8.jpg',
      ],
      txt: [],
      id: 17,
    },
    {
      label: 'Ictioplâncton*',
      check: false,
      path: 'ictioplancton',
      img: [
        'ictioplancton-1.jpg',
        'ictioplancton-2.jpg',
        'ictioplancton-3.jpg',
      ],
      txt: [],
      id: 18,
    },
    {
      label: 'Invertebrados Bentônicos*',
      check: false,
      path: 'invertebrados-bentonicos',
      img: [
        'invertebrados-bentonicos-1.jpg',
        'invertebrados-bentonicos-2.jpg',
        'invertebrados-bentonicos-3.jpg',
        'invertebrados-bentonicos-4.jpg',
        'invertebrados-bentonicos-5.jpg',
        'invertebrados-bentonicos-6.jpg',
        'invertebrados-bentonicos-7.jpg',
        'invertebrados-bentonicos-8.jpg',
      ],
      txt: [],
      id: 20,
    },
    {
      label: 'Macrófitas Aquáticas*',
      check: false,
      path: 'macrofitas',
      img: [
        'macrofitas-1.jpg',
        'macrofitas-2.jpg',
        'macrofitas-3.jpg',
        'macrofitas-4.jpg',
      ],
      txt: [],
      id: 21,
    },
    {
      label: 'Matrizes para bioacumulação',
      check: false,
      path: 'monitoramento-bioacumulacao',
      img: [
        'monitoramento-bioacumulacao-1.jpg',
        'monitoramento-bioacumulacao-2.jpg',
        'monitoramento-bioacumulacao-3.jpg',
      ],
      id: 25,
      txt: [],
    },
    {
      label: 'Nascentes e Minas*',
      check: false,
      path: 'nascentes-minas',
      img: ['nascentes-minas.jpg'],
      txt: [],
      id: 10,
    },
    {
      label: 'Poços Freáticos*',
      check: false,
      path: 'pocos-freaticos',
      img: ['pocos-freaticos.jpg'],
      txt: [],
      id: 8,
    },
    {
      label: 'Poços Profundos*',
      check: false,
      path: 'poco-profundo',
      img: ['poco-profundo.jpg'],
      txt: [],
      id: 9,
    },
    {
      label: 'Sistemas Industriais*',
      check: false,
      path: 'sistemas-industriais',
      img: ['sistemas-industriais.jpg'],
      txt: [],
      id: 13,
    },
    {
      label: 'Sedimentos*',
      check: false,
      path: 'sedimento',
      img: [
        'sedimento-1.jpg',
        'sedimento-2.jpg',
        'sedimento-3.jpg',
        'sedimento-4.jpg',
        'sedimento-5.jpg',
      ],
      txt: [],
      id: 14,
    },
    {
      label: 'Solos*',
      check: false,
      path: 'solos',
      img: ['solo-1.jpg', 'solo-2.jpg'],
      txt: [],
      id: 15,
    },
    {
      label: 'Zooplâcton*',
      check: false,
      path: 'zooplacton',
      img: ['zooplacton-1.jpg', 'zooplacton-2.jpg'],
      id: 26,
      txt: [],
    },
  ];

  listService = [
    {
      label: 'Acompanhamento da pluma de dragagens',
      check: false,
      path: 'acompanhamento-dragagens',
      img: [
        'acompanhamento-dragagens-1.jpg',
        'acompanhamento-dragagens-2.jpg',
        'acompanhamento-dragagens-3.jpg',
        'acompanhamento-dragagens-4.jpg',
      ],
      txt: [
        'Acompanhamento da dispersão da pluma da dragagem com derivadores de corrente superficial e de fundo realizando Amostragem de Água e Ensaios de Dados Ambientais em intervalos pré-determinados',
      ],
      id: '7',
    },
    {
      label: 'Monitoramento Praial',
      check: false,
      path: 'monitoramento-praial',
      img: [
        'monitoramento-praial-1.jpg',
        'monitoramento-praial-2.jpg',
        'monitoramento-praial-3.jpg',
      ],
      txt: [
        'Monitoramento de praias para determinação do perfil praial da dinâmica de marés e das espécies de fauna e flora ocorrentes ',
      ],
      id: '1',
    },
    {
      label: 'Monitoramento de Comunidade Vegetal',
      check: false,
      path: 'monitoramento-vegetal',
      img: [
        'monitoramento-vegetal-1.jpg',
        'monitoramento-vegetal-2.jpg',
        'monitoramento-vegetal-3.jpg',
        'monitoramento-vegetal-4.jpg',
        'monitoramento-vegetal-5.jpg',
        'monitoramento-vegetal-6.jpg',
        'monitoramento-vegetal-7.jpg',
        'monitoramento-vegetal-8.jpg',
        'monitoramento-vegetal-9.jpg',
      ],
      txt: [
        'Levantamento fitossociológico com dados biométricos fenológicos e fitossanitários',
        'Determinação dos índices ecológicos e de importância',
        'Acompanhamento da regeneração natural dos bosques',
        'Estudo de dossel',
      ],
      id: '2',
    },
    {
      label: 'Monitoramento de Bioacumulação',
      check: false,
      path: 'monitoramento-bioacumulacao',
      img: [
        'monitoramento-bioacumulacao-1.jpg',
        'monitoramento-bioacumulacao-2.jpg',
        'monitoramento-bioacumulacao-3.jpg',
      ],
      txt: [
        'Implantação das sementes e suas respectivas verificações e manutenções periódicas',
        'Coleta dos conjuntos ao final do período estabelecido',
        'Ensaio biométrico das sementes restantes',
        'Posterior encaminhamento para análise de bioacumulação em tecidos',
      ],
      id: '3',
    },
    {
      label: 'Monitoramento de Cetáceos',
      check: false,
      path: 'monitoramento-cetaceos',
      img: ['monitoramento-cetaceos-1.jpg', 'monitoramento-cetaceos-2.jpg'],
      txt: [
        'Monitoramento visual e hidroacústico, acompanhamento do comportamento e determinação dos sítios reprodutivos',
      ],
      id: '4',
    },
    {
      label: 'Monitoramento de Herpetofauna Aquática',
      check: false,
      path: 'monitoramento-aquatica',
      img: ['monitoramento-aquatica.jpg'],
      txt: [
        'Monitoramento dos sites de desova e levantamento e quantificação das espécies em ambientes aquáticos e terrestres ',
      ],
      id: '5',
    },
    {
      label: 'Monitoramento com Drone',
      check: false,
      path: 'mapeamento-drone',
      img: [
        'mapeamento-drone-1.jpg',
        'mapeamento-drone-2.jpg',
        'mapeamento-drone-3.jpg',
      ],
      txt: [
        'Determinação dos índices de qualidade vegetal',
        'Monitoramento da comunidade vegetal por sensoriamento remoto',
      ],
      id: '6',
    },
  ];

  tabs = [
    { label: 'Amostragem', check: true, list: this.listSample },
    {
      label: 'Serviços prestados em campo',
      check: false,
      list: this.listService,
    },
  ];

  objContent: any = {};
  changeImgs = true;

  @ViewChild('sample', { static: false }) sample: ElementRef = {} as ElementRef;
  @ViewChild('sampleTitle', { static: false }) sampleTitle: ElementRef =
    {} as ElementRef;

  constructor() {}

  ngOnInit(): void {
    // popula com primeiro item
    this.objContent = this.listSample[0];

    this.checkItem(this.listSample[0], this.listSample);
  }

  ngAfterViewInit() {
    let end =
      this.sampleTitle.nativeElement.offsetTop +
      this.sample.nativeElement.getBoundingClientRect().height;
    let start = this.sampleTitle.nativeElement.offsetTop + 90;

    if (start && end) this.startEndSample.emit({ start, end });
  }

  changeSwiper() {
    Swiper.use([Navigation, Pagination, Autoplay]);
    const swiper = new Swiper('.swiper-img', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

  checkItem(item: any, lista: any) {
    this.objContent = {};
    lista.map((obj: any) => (obj.check = false));
    item.check = true;

    let findItem = lista.find((obj: any) => obj.id == item.id);
    this.objContent = findItem;

    if (this.sampleTitle.nativeElement && this.mobile) {
      window.scrollTo({
        top: this.sampleTitle.nativeElement.offsetTop - 170,
        behavior: 'smooth',
      });
    }

    this.changeImgs = false;
    setTimeout(() => {
      this.changeImgs = true;
    }, 50);

    setTimeout(() => {
      this.changeSwiper();
    }, 50);
  }

  tabsChange(item: any) {
    this.objContent = {};
    this.tabs.map((obj: any) => (obj.check = false));

    item.check = true;
    item.list.map((objList: any) => (objList.check = false));
    item.list[0].check = true;
    this.objContent = item.list[0];

    this.checkItem(item.list[0], item.list);
  }
}
