import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  faUserCheck,
  faFileAlt,
  faChartBar,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

@Component({
  selector: 'consultancy',
  templateUrl: './consultancy.component.html',
  styleUrls: ['./consultancy.component.scss'],
})
export class ConsultancyComponent implements OnInit {
  @Output() startEndConsultancy = new EventEmitter();

  iconBox1 = faUserCheck;
  iconBox2 = faChartBar;
  iconBox3 = faFileAlt;
  iconCheck = faCheck;

  @ViewChild('consultancy', { static: false }) consultancy: ElementRef =
    {} as ElementRef;
  @ViewChild('consultancyTitle', { static: false })
  consultancyTitle: ElementRef = {} as ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let end =
      this.consultancyTitle.nativeElement.offsetTop +
      this.consultancy.nativeElement.getBoundingClientRect().height;
    let start = this.consultancyTitle.nativeElement.offsetTop - 140;

    if (start && end) this.startEndConsultancy.emit({ start, end });

    Swiper.use([Navigation, Pagination, Autoplay]);
    const swiper = new Swiper('.swiper-consultoria-img', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
}
