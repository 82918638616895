<section class="data-numbers" #numbers>
  <div class="container">
    <div class="titles">
      <h1 #numbersTitle>Alguns indicadores AQTCA</h1>
      <p></p>
    </div>

    <div class="row numbers">
      <div class="col-lg-3 col-6 text-center">
        <span
          ><ng-animated-counter
            [params]="amostras"
            *ngIf="updateComponent"
          ></ng-animated-counter
          >+
        </span>
        <p>Amostras</p>
      </div>
      <div class="col-lg-3 col-6 text-center">
        <span
          ><ng-animated-counter
            [params]="campAmostragem"
            *ngIf="updateComponent"
          ></ng-animated-counter
          >+
        </span>
        <p>Campanhas de amostragem</p>
      </div>
    </div>
  </div>
</section>
