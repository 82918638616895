<div class="tabs-forms">
  <div class="tabs">
    <ul>
      <li
        *ngFor="let item of tabs"
        [ngClass]="{ check: item.check }"
        (click)="tabsChange(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>

  <div *ngFor="let item of tabs; let i = index">
    <form
      action="enviar_contato.php"
      method="post"
      role="form"
      class="php-email-form"
      enctype="multipart/form-data"
      *ngIf="item.check && i == 0"
      id="form_contato"
    >
      <div class="row">
        <div class="col-md-6 form-group mt-4">
          <input
            type="text"
            name="nome"
            class="form-control"
            id="nome"
            placeholder="Nome"
            required
          />
        </div>
        <div class="col-md-6 form-group mt-4">
          <input
            type="text"
            name="empresa"
            class="form-control"
            id="empresa"
            placeholder="Empresa"
            required
          />
        </div>
        <div class="col-md-6 form-group mt-4">
          <input
            type="email"
            class="form-control"
            name="email"
            id="email"
            placeholder="Email"
            required
          />
        </div>

        <div class="col-md-6 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="telefone"
              id="telefone"
              placeholder="Telefone"
              required
              mask="(00) 0000-00000"
            />
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="assunto"
              id="assunto"
              placeholder="Assunto"
              required
            />
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div class="form-group mt-4 mb-2">
            <textarea
              class="form-control"
              name="mensagem"
              id="mensagem"
              rows="5"
              placeholder="Mensagem"
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div class="text-center pb-4">
        <button type="submit">Enviar</button>
      </div>
    </form>

    <form
      action="enviar_trabalhe.php"
      method="post"
      role="form"
      class="php-email-form"
      enctype="multipart/form-data"
      id="form_trabalhe"
      *ngIf="item.check && i == 1"
    >
      <div class="row">
        <div class="col-md-3 form-group mt-4">
          <input
            type="text"
            name="nome_tr"
            class="form-control"
            id="nome_tr"
            placeholder="Nome"
            required
          />
        </div>

        <div class="col-md-3 form-group mt-4">
          <input
            type="email"
            class="form-control"
            name="email_tr"
            id="email_tr"
            placeholder="Email"
            required
          />
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="telefone_tr"
              id="telefone_tr"
              placeholder="Telefone"
              required
              mask="(00) 0000-00000"
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="celular_tr"
              id="celular_tr"
              placeholder="Celular"
              required
              mask="(00) 0000-00000"
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="endereco_tr"
              id="endereco_tr"
              placeholder="Endereço"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="cidade_tr"
              id="cidade_tr"
              placeholder="Cidade"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4 select-bullet">
            <select
              class="form-control"
              name="estado_tr"
              id="estado_tr"
              required
            >
              <option value="">Estado</option>
              <option *ngFor="let state of listState" [value]="state.value">
                {{ state.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4 select-bullet">
            <select class="form-control" name="cargo_tr" id="cargo_tr" required>
              <option *ngFor="let state of listOffice" [value]="state.value">
                {{ state.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4 select-bullet">
            <select
              class="form-control"
              name="escolaridade_tr"
              id="escolaridade_tr"
              required
            >
              <option *ngFor="let state of listSchooling" [value]="state.value">
                {{ state.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="formacao_tr"
              id="formacao_tr"
              placeholder="Formação"
              required
            />
          </div>
        </div>

        <div class="col-md-6 form-group">
          <div class="form-group mt-4 mb-2">
            <label>Anexar Currículo: </label>
            <input
              type="file"
              id="file_curriculo"
              class="input-file"
              name="file_curriculo"
            />
          </div>
        </div>
      </div>

      <div class="text-center pt-2 pb-4">
        <button type="submit">Enviar</button>
      </div>
    </form>

    <form
      action="enviar_fornecedor.php"
      method="post"
      role="form"
      class="php-email-form"
      enctype="multipart/form-data"
      id="form_trabalhe"
      *ngIf="item.check && i == 2"
    >
      <div class="row">
        <div class="col-md-3 form-group mt-4">
          <input
            type="text"
            name="nome_sj"
            class="form-control"
            id="nome_sj"
            placeholder="Nome"
            required
          />
        </div>

        <div class="col-md-3 form-group mt-4">
          <input
            type="email"
            class="form-control"
            name="email_sj"
            id="email_sj"
            placeholder="Email"
            required
          />
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="cnpj_sj"
              id="cnpj_sj"
              placeholder="CNPJ"
              required
              mask="00.000.000/0000-00"
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="inscricao_sj"
              id="inscricao_sj"
              placeholder="Inscrição Estadual"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="site_sj"
              id="site_sj"
              placeholder="Web Site"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="telefone_sj"
              id="telefone_sj"
              placeholder="Telefone"
              required
              mask="(00) 0000-00000"
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="bairro_sj"
              id="bairro_sj"
              placeholder="Bairro"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="cep_sj"
              id="cep_sj"
              placeholder="Cep"
              required
              mask="00000-000"
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="cidade_sj"
              id="cidade_sj"
              placeholder="Cidade"
              required
            />
          </div>
        </div>

        <div class="col-md-3 form-group">
          <div class="form-group mt-4 select-bullet">
            <select
              class="form-control"
              name="estado_sj"
              id="estado_sj"
              required
            >
              <option value="">Estado</option>
              <option *ngFor="let state of listState" [value]="state.value">
                {{ state.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6 form-group">
          <div class="form-group mt-4 mb-2">
            <label>Anexar Portfólio: </label>
            <input
              type="file"
              id="file_portfolio"
              class="input-file"
              name="file_portfolio"
            />
          </div>
        </div>

        <div class="col-md-6 form-group">
          <div class="form-group mt-4">
            <input
              type="text"
              class="form-control"
              name="endereco_sj"
              id="endereco_sj"
              placeholder="Endereço"
              required
            />
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div class="form-group mt-4 mb-2">
            <textarea
              class="form-control"
              name="mensagem_sj"
              id="mensagem_sj"
              rows="5"
              placeholder="Mensagem"
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div class="text-center pt-2 pb-4">
        <button type="submit">Enviar</button>
      </div>
    </form>
  </div>

  <div class="my-3">
    <div class="loading">Carregando...</div>
    <div class="error-message">Ocorreu um erro ao enviar</div>
    <div *ngIf="sentMessage" class="sent-message">Mensagem enviada !</div>
  </div>
</div>
