import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'footer-office',
  templateUrl: './footer-office.component.html',
  styleUrls: ['./footer-office.component.scss'],
})
export class FooterOfficeComponent implements OnInit {
  @Output() changeMenu = new EventEmitter();
  @Output() startEndFooter = new EventEmitter();

  iconTwitter = faTwitter;
  iconFacebook = faFacebook;
  iconInstagran = faInstagram;
  iconLinkedin = faLinkedin;
  iconLink = faChevronRight;

  constructor() {}

  ngOnInit(): void {}

  goMenu(menu: any) {
    this.changeMenu.emit(menu);
  }
}
